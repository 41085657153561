import i18n from '@/i18n';
import { HttpError } from '@/interfaces/api.interface';
import { FormInstance } from 'antd';
import { TFunction } from 'i18next';

export const validateSlugField = (
  _: any,
  value: string,
  callback: (message?: string) => void,
) => {
  if (value && !/^[a-z0-9_-]+$/.test(value)) {
    callback(`${i18n.t('validations.alphaLower')}`);
  } else {
    callback();
  }
};

export const validatePhoneField = (
  _: any,
  value: string,
  callback: (message?: string) => void,
) => {
  if (value && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value)) {
    callback(`${i18n.t('validations.phone')}`);
  } else {
    callback();
  }
};

export const getValidationDescription = (
  response: HttpError,
  form: FormInstance,
  translate: TFunction,
): string | undefined => {
  const message: string[] = [];

  if (Array.isArray(response.data)) {
    for (const single of response.data) {
      message.push(
        `${single.path.length ? single.path[0] : ''} -
        ${single.message.charAt(0).toUpperCase() + single.message.slice(1)}`,
      );
    }
  } else {
    switch (response.data.code) {
      case 11000:
        // eslint-disable-next-line no-case-declarations
        const keyValue = Object.values(response.data.keyValue).join(' / ');
        console.log('Key pattern: ', keyValue);
        message.push(`${translate('errors.E11000', { keyValue })}`);
        break;
    }
  }

  return message.length ? message.join('\n') : undefined;
};
